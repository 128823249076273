<template>
  <v-dialog
    v-model="dialog"
    persistent
    content-class="ml-0 mr-n16 my-0 custom-dialog-height"
    transition="dialog-bottom-transition"
    fullscreen
  >
    <div class="row-bg-color pr-0 poppins custom-body-height">
      <div class="modal-header h-60px bg-white align-center">
        <h3 class="mb-0 font-weight-bolder">Receiving</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="resetReceivingData"
        >
          <span class="svg-icon">
            <v-icon size="20">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div
        class="modal-body custom-container-height d-flex flex-column ml-3 mr-0 py-7"
      >
        <v-row class="ma-0 h-100">
          <v-col cols="6" class="custom-right-border pa-0 h-100">
            <div class="bg-white h-100 custom-overflow rounded-lg pa-4 pt-0">
              <SKUSDataTable />
            </div>
          </v-col>
          <v-col cols="6" class="custom-left-border pa-0 h-100">
            <ReceivingDataTable :asn_number="asnBarcode" />
          </v-col>
        </v-row>
      </div>
      <div
        class="modal-footer bg-white h-60px py-0 pr-10 d-flex align-center justify-end"
      >
        <button @click="submitForm" class="btn btn-info">Save changes</button>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import ReceivingDataTable from "@/own/components/warehouseManagement/receiving/ReceivingDataTable";
import SKUSDataTable from "@/own/components/warehouseManagement/receiving/SKUSDataTable";
import {
  SET_SCANNED_SKUS,
  SET_SKUS,
  UPDATE_TABLE_DATA,
} from "@/core/services/store/receiving.module";

export default {
  name: "ReceivingDialog",
  components: { SKUSDataTable, ReceivingDataTable },
  beforeMount() {
    this.$store.commit(SET_PAGE_LOADING, false);
  },
  data: function () {
    return {
      asnBarcode: null,
      dialog: false,
    };
  },
  methods: {
    submitForm() {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("/warehouse_management/receiving/update", {
        asn_number: this.asnBarcode,
        type: this.$store.getters.getWMSRECEIVINGBlindReceive
          ? "blind_receiving"
          : "receiving",
        skus: this.$store.getters.getWMSRECEIVINGScannedSKUS,
      })
        .then(async () => {
          await this.$store
            .dispatch(UPDATE_TABLE_DATA, {
              ...this.$store.getters.getWMSRECEIVINGTableState,
              warehouse_id: this.$store.getters.getSelectedWarehouse,
            })
            .then(() => {
              const unReceivedSKUS =
                this.$store.getters.getWMSRECEIVINGTableData?.data.find(
                  (item) => item.asn_number === this.asnBarcode
                );

              if (
                unReceivedSKUS &&
                unReceivedSKUS.status_label === "Partially received"
              ) {
                ApiService.post("/warehouse_management/receiving/show", {
                  asn_number: this.asnBarcode,
                }).then(({ data }) => {
                  const unreceivedSKUS = data.skus.filter(
                    (item) => item.status !== 2
                  );
                  if (unreceivedSKUS.length) {
                    Swal.fire({
                      title: "Success",
                      text: "Successfully received, please scan other items also",
                      icon: "success",
                      showConfirmButton: true,
                    });

                    this.$store.commit(SET_SKUS, data.skus);
                    this.$store.commit(SET_SCANNED_SKUS, []);
                  }
                });
              } else if (unReceivedSKUS === undefined) {
                Swal.fire({
                  title: "Success",
                  text: "Successfully received",
                  icon: "success",
                  showConfirmButton: false,
                  timer: 2500,
                });
                this.resetReceivingData();
              }
            });
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    toggleModal(asnBarcode) {
      this.asnBarcode = asnBarcode;
      this.dialog = true;
    },
    resetReceivingData() {
      this.asnBarcode = null;
      this.$store.commit(SET_SCANNED_SKUS, []);
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.row-bg-color {
  background-color: #eef0f8;
}

.custom-right-border {
  border-right: 14px solid #eef0f8;
}

.custom-left-border {
  border-left: 14px solid #eef0f8;
}

.custom-overflow {
  overflow-y: scroll;
  position: relative;
}

.custom-body-height {
  height: 100vh !important;
}

.custom-container-height {
  height: calc(100vh - 120px) !important;
}

@media (max-width: 1199.98px) {
  .custom-container-height {
    height: calc(100vh - 141px) !important;
  }
}

@media (max-width: 991.98px) {
  .custom-container-height {
    height: calc(100vh - 127px) !important;
  }
}

.custom-overflow::-webkit-scrollbar {
  width: 12px !important;
}
.custom-overflow::-webkit-scrollbar-thumb {
  border-radius: 12px !important;
}
</style>
