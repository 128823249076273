<template>
  <v-dialog transition="dialog-bottom-transition" max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <span
        v-on="on"
        v-bind="attrs"
        class="symbol symbol-25 symbol-sm-35 symbol-light-success overflow-hidden mr-3"
      >
        <img
          style="border-radius: 50% !important"
          v-if="item.photo.url"
          alt="photo"
          :src="item.photo.url"
        />
        <span
          v-else
          style="border-radius: 50% !important"
          class="symbol-label font-size-base font-size-h5-sm bg-light-primary text-primary"
        >
          {{ item.sku[0].toUpperCase() }}
        </span>
      </span>
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-card-text class="pa-4 text-center">
          <img v-if="item.photo.url" :src="item.photo.url" alt="image" />
          <span
            v-else
            style="border-radius: 50% !important"
            class="symbol-label font-size-base font-size-h5-sm bg-light-primary text-primary"
          >
            {{ item.sku[0].toUpperCase() }}
          </span>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="red" dark @click="dialog.value = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
export default {
  name: "ShowImage",
  props: ["item"],
};
</script>
