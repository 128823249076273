<template>
  <div class="mt-6">
    <v-data-table
      fixed-header
      light
      dense
      :items="items"
      item-key="asn_number"
      :server-items-length="items.length"
      :headers="headers"
      :items-per-page="itemPerPage"
      hide-default-footer
      mobile-breakpoint="300"
    >
      <!--    no-data-->
      <template v-slot:no-data> NO DATA HERE! </template>
      <template v-slot:no-results> NO RESULTS HERE! </template>
      <template
        v-for="(head, i) in headers"
        v-slot:[`item.`+head.value]="{ item, header, value }"
      >
        <ComponentSelector
          v-bind:key="i"
          :item="item"
          :header="header"
          :value="value"
        ></ComponentSelector>
      </template>

      <template #item.action="{ item, value }">
        <div class="d-flex align-items justify-center">
          <button
            class="btn iq-btn poppins mr-2"
            @click="() => handleReceiveClick(item.asn_number)"
          >
            Receive
          </button>
          <button
            class="btn iq-btn poppins text-no-wrap mr-2"
            :disabled="!item.extra_data.detail.actions.blind_receive"
            @click="() => handleBlindReceiveClick(item.asn_number)"
          >
            Blind receive
          </button>
          <button
            class="btn iq-btn poppins text-no-wrap"
            :disabled="!item.extra_data.detail.actions.blind_receive"
            @click="() => handleReceiveClick(item.asn_number, true)"
          >
            Simple receive
          </button>
        </div>
      </template>
    </v-data-table>
    <Pagination
      v-if="items.length"
      :setitemPerPage="setItemPerPage"
      :getitemPerPage="getItemPerPage"
      :getpageNumber="getPageNumber"
      :setpageNumber="setPageNumber"
      :totalItems="totalItems"
      :pageCount="pageCount"
      :updateTableContent="updateTableContent"
    ></Pagination>
    <ReceivingDialog ref="receivingDialog" />
  </div>
</template>

<script>
import ComponentSelector from "@/own/components/datatable/ComponentSelector";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import ApiService from "@/core/services/api.service";
import ReceivingDialog from "@/own/components/warehouseManagement/receiving/ReceivingDialog";
import Pagination from "@/own/components/pagination/Pagination";
import {
  SET_BLIND_RECEIVE,
  SET_RECEIVE_TYPE,
  SET_SCANNED_SKUS,
  SET_SIMPLE_RECEIVE,
  SET_SKUS,
  SET_TABLE_PAGE,
  SET_TABLE_PERPAGE,
  UPDATE_TABLE_DATA,
} from "@/core/services/store/receiving.module";

export default {
  name: "DataTable",
  components: { Pagination, ComponentSelector, ReceivingDialog },
  methods: {
    handleBlindReceiveClick(asn_number) {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("/warehouse_management/receiving/show", {
        asn_number: asn_number,
      })
        .then(({ data }) => {
          this.$store.commit(SET_SKUS, data.skus);
          this.$store.commit(SET_BLIND_RECEIVE, true);
          this.$store.commit(SET_SIMPLE_RECEIVE, false);
          this.$store.commit(SET_RECEIVE_TYPE, data.receiving_type);
          this.$store.commit(SET_SCANNED_SKUS, []);
          this.$refs.receivingDialog.toggleModal(asn_number);
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    handleReceiveClick(asn_number, simpleReceive = false) {
      this.$store.commit(SET_PAGE_LOADING, true);
      ApiService.post("/warehouse_management/receiving/show", {
        asn_number: asn_number,
      })
        .then(({ data }) => {
          if (data.receiving_type === 3) {
            let uniqueCartons = data.skus.reduce((accumulator, sku) => {
              if (
                accumulator.findIndex(
                  (item) => item.carton_number === sku.carton_number
                ) === -1
              )
                accumulator.push(sku);

              return accumulator;
            }, []);

            this.$store.commit(SET_SKUS, uniqueCartons);
          } else this.$store.commit(SET_SKUS, data.skus);
          this.$store.commit(SET_BLIND_RECEIVE, false);
          this.$store.commit(SET_SIMPLE_RECEIVE, simpleReceive);
          this.$store.commit(SET_RECEIVE_TYPE, data.receiving_type);
          this.$store.commit(SET_SCANNED_SKUS, []);
          this.$refs.receivingDialog.toggleModal(asn_number);
        })
        .finally(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
    },
    setItemPerPage(val) {
      this.$store.commit(SET_TABLE_PERPAGE, val);
      this.$store.commit(SET_TABLE_PAGE, 1);
    },
    setPageNumber(val) {
      this.$store.commit(SET_TABLE_PAGE, val);
    },
    async updateTableContent() {
      this.$store.commit(SET_PAGE_LOADING, true);
      await this.$store
        .dispatch(UPDATE_TABLE_DATA, {
          ...this.$store.getters.getWMSRECEIVINGTableState,
          warehouse_id: this.$store.getters.getSelectedWarehouse,
        })
        .then(() => {
          this.$store.commit(SET_PAGE_LOADING, false);
        });
      this.$forceUpdate();
    },
  },
  computed: {
    //pagination
    getItemPerPage: function () {
      return this.$store.getters.getWMSRECEIVINGTableState.per_page;
    },
    getPageNumber: function () {
      return this.$store.getters.getWMSRECEIVINGTableState.page;
    },
    totalItems: function () {
      return this.$store.getters.getWMSRECEIVINGTableData?.total;
    },
    pageCount: function () {
      return this.$store.getters.getWMSRECEIVINGTableData?.last_page;
    },

    items: function () {
      let items = [];
      if (this.$store.getters.getWMSRECEIVINGTableData) {
        items = this.$store.getters.getWMSRECEIVINGTableData?.data;
      }
      return items;
    },
    itemPerPage: function () {
      return this.$store.getters.getWMSRECEIVINGTableState.per_page;
    },

    headers: function () {
      let headers = [];

      if (this.$store.getters.getWMSRECEIVINGTableHeaders.length) {
        headers = this.$store.getters.getWMSRECEIVINGTableHeaders.filter(
          (col) => col.visible === true
        );
      }
      headers.forEach((header) => {
        header.class =
          "text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        header.itemClass = "second-text";
        if (header.value === "action") {
          header.class =
            "text-center text-muted fw-bolder font-size-sm text-uppercase poppins-ls";
        }
      });
      return headers;
    },
  },
};
</script>
