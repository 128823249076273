<template>
  <v-data-table
    dense
    fixed-header
    class="my-6 pl-4 pr-8"
    :headers="skuHeaders"
    :items="$store.getters.getWMSRECEIVINGSKUS"
    :items-per-page="-1"
    hide-default-footer
    mobile-breakpoint="300"
  >
    <template
      v-slot:item.sku="{ item }"
      v-if="$store.getters.getWMSRECEIVINGReceivingType !== 3"
    >
      <div class="max-w-250px d-flex align-center">
        <ShowImage :item="item" />

        <div class="d-flex justify-content-start flex-column">
          <span class="font-size-lg text-hover-info mb-1">{{ item.sku }}</span>
          <span class="text-dark-25 text-wrap font-size-sm">{{
            item.description
          }}</span>
        </div>
      </div>
    </template>

    <template
      v-slot:item.expected_qty="{ value }"
      v-if="$store.getters.getWMSRECEIVINGReceivingType !== 3"
      >{{ value }}
    </template>

    <template
      v-slot:item.received_qty="{ value }"
      v-if="$store.getters.getWMSRECEIVINGReceivingType !== 3"
      >{{ value }}
    </template>

    <template v-slot:item.carton_number="{ value }">
      {{ value }}
    </template>

    <template v-slot:item.flags="{ item }">
      <!--      <template v-for="(flag, index) in item">-->
      <div class="d-flex flex-wrap max-w-250px ma-2">
        <span
          v-if="item.label_flag"
          class="text-center badge badge-light-success font-size-sm my-2 mr-2 py-2 px-4"
        >
          Labelled
        </span>
        <span
          v-if="item.bundle_flag"
          class="text-center badge badge-light-primary font-size-sm my-2 mr-2 py-2 px-4"
        >
          Bundled
        </span>
        <span
          v-if="item.qc_flag"
          class="text-center badge badge-light-info font-size-sm my-2 mr-2 py-2 px-4"
        >
          QC
        </span>
        <span
          v-if="item.assemble_flag"
          class="text-center badge badge-light-warning font-size-sm my-2 mr-2 py-2 px-4"
        >
          Assembled
        </span>
        <span
          v-if="item.exp_flag"
          class="text-center badge badge-light-danger font-size-sm my-2 mr-2 py-2 px-4"
        >
          Expired
        </span>

        <span
          v-if="item.status === 1"
          class="text-center badge badge-light-warning font-size-sm my-2 mr-2 py-2 px-4"
        >
          Partially received
        </span>

        <span
          v-if="item.status === 2"
          class="text-center badge badge-light-primary font-size-sm my-2 mr-2 py-2 px-4"
        >
          Received
        </span>
      </div>
      <!--      </template>-->
    </template>
  </v-data-table>
</template>

<script>
import ShowImage from "@/own/components/warehouseManagement/receiving/ShowImage";
export default {
  name: "SKUSDataTable",
  components: { ShowImage },
  computed: {
    skuHeaders: function () {
      const headers = [
        {
          text: "Carton number",
          value: "carton_number",
          // width: 300,
          class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
          cellClass: "second-text",
        },
        {
          text: "Flags",
          value: "flags",
          // width: 250,
          class: "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
          cellClass: "second-text",
        },
      ];
      if (this.$store.getters.getWMSRECEIVINGReceivingType !== 3)
        headers.unshift(
          {
            text: "SKU",
            value: "sku",
            // width: 300,
            class:
              "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
            cellClass: "second-text",
          },
          {
            text: "Expected quantity",
            value: "expected_qty",
            // width: 300,
            align: "center",
            class:
              "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
            cellClass: "second-text",
          },
          {
            text: "Received quantity",
            value: "received_qty",
            // width: 300,
            align: "center",
            class:
              "text-muted  fw-bolder font-size-sm text-uppercase poppins-ls",
            cellClass: "second-text",
          }
        );

      return headers;
    },
  },
};
</script>
